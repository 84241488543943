<template>
  <loader-view v-if="loading" />

  <v-container v-else class="py-12">
    <v-row v-if="contracts.length > 0">
      <v-col v-for="(item, i) in contracts" :key="i" cols="12">
        <PropertyCard :property="item" />
      </v-col>
    </v-row>

    <div v-else class="text-center">
      <span > Nenhuma UCS foi encontrada </span>
    </div>
  </v-container>
</template>

<script>
import { request } from "@/services";
import PropertyCard from "@/components/myProperties/PropertyCard.vue";

export default {
  data() {
    return {
      loading: true,
      contracts: [],
    };
  },

  components: { PropertyCard },

  beforeMount() {
    this.getContracts();
  },

  methods: {
    async getContracts() {
      try {
        const payload = { method: "getUserContratos" };

        await request(payload).then((res) => {
          this.contracts = res.userContratos.map((el) => {
            return {
              id: el.id,
              name: el.nomeContrato,
              image: el.iconeContrato,
              currentValue: el.saldoAtual,
              profit7days: el.semanaRend,
              profit24hours: el.ontemRend,
              profit: Number(el.saldoAtual) - Number(el.valorDeposito),
              propertyDate: el.dataContrato,
            };
          });
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
