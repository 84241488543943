<template>
  <v-card class="rounded-lg pa-6" height="100%" outlined @click="submit()">
    <!-- title -->
    <div class="subtext--text mb-4">
      <span >
        UCS adquirida {{ dateFormat(property.propertyDate) }}.
      </span>
      <span class="primary--text font-weight-bold">
        <span > Detalhes </span>
        <v-icon color="primary" class="ml-n1" small>mdi-chevron-right</v-icon>
      </span>
    </div>

    <v-row>
      <v-col col="12" md="4">
        <v-card height="100%" class="rounded-lg" outlined>
          <v-sheet class="rounded-t-lg rounded-b-0" color="accent">
            <v-responsive :aspect-ratio="16 / 9">
              <v-img
                v-if="property.image"
                class="rounded-t-lg rounded-b-0"
                :src="_image"
                :aspect-ratio="16 / 9"
              />
            </v-responsive>
          </v-sheet>

          <div class="pa-4">
            <div class="text-body-1">
              <span > UCS </span>
            </div>
            <div class="text-h6 font-weight-bold">
              <span >
                {{ property.name }}
              </span>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" md="8">
        <v-card height="100%" class="pa-4 rounded-lg" outlined>
          <div class="text-h6 secondary--text font-weight-bold mb-4">
            <span > Ganhos por arrendamento </span>
          </div>

          <v-row>
            <v-col>
              <div class="pa-3">
                <div class="text-h4 secondary--text font-weight-bold">
                  <span >
                    {{ currencyMask(property.profit24hours) }}
                  </span>
                  <span class="text-h6">
                    <span > BRL </span>
                  </span>
                </div>
                <div class="subtext--text text-body-1">
                  <span > Acumulado últimas 24 horas </span>
                </div>
              </div>
            </v-col>

            <v-divider class="my-6" vertical />

            <v-col>
              <div class="pa-3">
                <div class="text-h4 secondary--text font-weight-bold">
                  <span >
                    {{ currencyMask(property.profit7days) }}
                  </span>
                  <span class="text-h6">
                    <span > BRL </span>
                  </span>
                </div>
                <div class="subtext--text text-body-1">
                  <span > Acumulado essa semana </span>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-divider class="my-2" />

          <v-row>
            <v-col>
              <div class="pa-3">
                <div class="text-h4 red--text font-weight-bold">
                  <span >
                    {{ currencyMask(property.profit) }}
                  </span>
                  <span class="text-h6">
                    <span > BRL </span>
                  </span>
                </div>
                <div class="subtext--text text-body-1">
                  <span > Total acumulado </span>
                </div>
              </div>
            </v-col>

            <v-divider class="my-6" vertical />

            <v-col>
              <div class="pa-3">
                <div class="text-h4 secondary--text font-weight-bold">
                  <span >
                    {{ currencyMask(property.currentValue) }}
                  </span>
                  <span class="text-h6">
                    <span > BRL </span>
                  </span>
                </div>
                <div class="subtext--text text-body-1">
                  <span > Valor total de contrato </span>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { currencyMask, formatAmount } from "@/utils";
const moment = require("moment");
moment.locale("pt-br");

export default {
  props: {
    property: {
      type: Object,
      required: true,
    },
  },

  computed: {
    _image() {
      return this.$store.getters.apiURL + this.property.image;
    },
  },

  methods: {
    submit() {
      this.$router.push({ path: `/my-properties/${this.property.id}` });
    },

    dateFormat(value) {
      return moment(value).format("ll");
    },

    currencyMask,

    formatAmount,
  },
};
</script>

<style lang="scss" scoped></style>
