const moment = require("moment");

export function displayAlert(message, type = 0, fixed = false) {
  this.$root.$emit("display-alert", {
    message: message,
    type: type,
    fixed: fixed,
  });
}

// format digits of decimal point
export function formatAmount(value, decimal = 2) {
  if (!value) value = 0;

  const og = Math.pow(10, decimal);

  value = Math.floor(value * og) / og;

  return Number(value.toFixed(decimal));
}

// add currency mask formatting
export function currencyMask(value, min = 2, max = 2) {
  if (!value) value = 0;

  return Number(value).toLocaleString("pt-BR", {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
}

// remove money mask
export function parseNumber(value) {
  return Number(value.replace(/\./g, "").replace(/,/g, "."));
}

// capitalize all words
export function capitalizeAll(value) {
  const values = value.split(" ");

  return values
    .map((e) => capitalize(e))
    .toString()
    .replaceAll(",", " ");
}

// capitalize first word
export function capitalize(value) {
  return (
    value.toString().charAt(0).toUpperCase() +
    value.toString().substring(1).toLowerCase()
  );
}

// handle pagination
export function handlePagination(value) {
  value = value / 15;
  if (value % 1 !== 0) value = value + 1;

  return Math.trunc(value);
}

// allow only input event key numbers
export function onlyNumber(event) {
  event = event ? event : window.event;
  let charCode = event.which ? event.which : event.keyCode;

  if (charCode < 48 || charCode > 57) return event.preventDefault();
  else true;
}

// block input event of key numbers
export function blockNumber(event) {
  event = event ? event : window.event;
  let charCode = event.which ? event.which : event.keyCode;

  if (charCode < 48 || charCode > 57) return true;
  else event.preventDefault();
}

export function formatDate(value) {
  if (!value) return "";

  return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
}

export function revertDate(value) {
  if (!value) return "";

  return moment(value, "DD/MM/YYYY").format("YYYY-MM-DD");
}

export function formatDocument(value) {
  if (value.length === 11)
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

  if (value.length === 14)
    return value.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );

  return value;
}
